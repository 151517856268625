$prefix: bs- !default;

.prevent-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fc-button {
  padding: 0.75rem 1.25rem;
  box-shadow: none !important;
  border: 0 !important;
  border-radius: 0.475rem;
  vertical-align: middle;
  font-weight: 500;
  text-transform: capitalize;
}

.fc-button-primary {
  margin: 0;

  // Icon
  .fc-icon {
    font-size: 1.35rem;
    margin-bottom: 0.15rem;
  }

  &:not(:disabled):not(.fc-button-active) {
    &:focus,
    &:hover,
    &:active {
      color: var(--#{$prefix}gray-900);

      .fc-icon {
        color: var(--#{$prefix}gray-900);
      }
    }
  }

  &:not(:disabled).fc-button-active {
    color: var(--#{$prefix}gray-900);

    .fc-icon {
      color: var(--#{$prefix}gray-900);
    }
  }
}

// Button group
.fc-button-group {
  .fc-button {
    margin: 0 !important;
  }
}

.fc-button-primary {
  margin: 0;
  height: max-content;

  // Icon
  .fc-icon {
    font-size: 1.35rem;
    margin-bottom: 0.15rem;
  }

  &:not(:disabled):not(.fc-button-active) {
    &:focus,
    &:hover,
    &:active {
      color: var(--#{$prefix}gray-900);

      .fc-icon {
        color: var(--#{$prefix}gray-900);
      }
    }
  }

  &:not(:disabled).fc-button-active {
    color: var(--#{$prefix}gray-900);

    .fc-icon {
      color: var(--#{$prefix}gray-900);
    }
  }
}

.fc-header-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rbc-header {
  border-bottom: 1px solid #e9ecef;
}

.rbc-time-view {
  border: 1px solid #e9ecef;
}

.rbc-time-header-gutter {
  border-right: 1px solid #e9ecef !important;
}

.rbc-time-header-content {
  border-left: 1px solid #e9ecef;
}

.rbc-time-content {
  border-top: 1px solid #e9ecef;
}

.rbc-timeslot-group {
  border-bottom: 1px solid #e9ecef;
  min-height: 64px !important;
}

.rbc-time-header-content {
  > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #e9ecef;
  }
}

.rbc-day-slot {
  .rbc-time-slot {
    border-top: 1px solid #e9ecef;
  }
}

.rbc-time-content {
  > * {
    + {
      * {
        > * {
          border-left: 1px solid #e9ecef;
        }
      }
    }
  }
}

.rbc-event {
  overflow-y: auto !important;
}

/* CUSTOM WIT */

// style for react-select placeholder by asep
.css-1jqq78o-placeholder {
  color: var(--bs-gray-500) !important;
  font-weight: 500;
  line-height: 1.5;
  font-size: 1.1rem;
}

// dropdown
.custom-dropdown-control {
  border: 1px solid var(--bs-gray-300) !important;
  font-size: 1.1rem;
  border-radius: 0.475rem !important;
  padding: 0.25rem 0.1rem;
}

.custom-dropdown-placeholder {
  color: var(--bs-gray-500);
}

// quill
.ql-toolbar.ql-snow {
  border: 1px solid var(--bs-gray-300) !important;
}

.ql-container.ql-snow {
  border: 1px solid var(--bs-gray-300) !important;
  min-height: 100px;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px !important;
}

.css-13cymwt-control {
  border-radius: 0.625rem !important;
  border: 1px solid var(--bs-gray-300) !important;
  color: var(--bs-gray-700) !important;
}

.css-1dimb5e-singleValue {
  color: var(--bs-gray-700) !important;
  font-size: 1.15rem !important;
}

.table:not(.table-bordered).table-row-dashed tr {
  border-color: #ccc;
}

// RBC DnD Calendar
.rbc-event-label {
  color: white;
}

.no-padding-table {
  .table {
    > :not(caption) > * > * {
      padding: 0 0;
    }
  }
}

.text-sm {
  font-size: 12px !important;
}

.font-regular {
  font-weight: normal;
}

.freeze-table {
  position: absolute;
  display: inline-block;
  background-color: white;
  box-shadow: 4px 4px 10px -3px rgba(0, 0, 0, 0.16);
}

.freeze-shadow {
  box-shadow: 4px 4px 10px -3px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 4px 4px 10px -3px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 4px 4px 10px -3px rgba(0, 0, 0, 0.16);
}

.shadow-wit::before {
  background: rgba(77, 105, 250, 0.3);
  -webkit-backdrop-filter: blur(0.5rem);
  backdrop-filter: blur(0.5rem);
  will-change: backdrop-filter;
  z-index: -1;
  top: 1rem;
  right: 1rem;
  left: 1rem;
  position: absolute;
  height: 100%;
  border-radius: inherit;
  box-shadow: none;
  content: '';
  transition: all 0.2s ease-in-out;
}

.shadow-wit-2 {
  box-shadow: 0 1.6rem 3rem rgba(0, 0, 0, 0.1);
}

.shadow-wit::after {
  position: absolute;
  height: 100%;
  border-radius: inherit;
  box-shadow: none;
  content: '';
  transition: all 0.2s ease-in-out;
  background: rgba(77, 105, 250, 0.15);
  z-index: -2;
  top: 1.75rem;
  right: 2rem;
  left: 2rem;
}
.max-two-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.max-single-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.z-10 {
  z-index: 10;
}

.z-9 {
  z-index: 9;
}

.backdrop-order {
  right: -200px;
}

.bg-disabled {
  background-color: #f2f2f2;
}

.text-disabled {
  color: #99a1b7; /* Example disabled text color */
}

.bg-illustration-order {
  background-image: url('../../../../public/media/illustrations/ilustration_card_order.png');
  background-color: #fff;
  height: 100%;
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 300px;
}

.cv-content {
  i {
    font-size: 2.5rem;
  }
}

// Hide timepicker icon
input[type='time']::-webkit-calendar-picker-indicator {
  background: none;
}

// bs dropdown
#sales-invoice-actions {
  & .dropdown {
    position: unset;
  }
}

// leaflet
.leaflet-container {
  height: 500px;
}

.leaflet-div-icon {
  border: unset !important;
  background-color: transparent !important;
}

// print
@media print {
  @page {
    size: portrait;
    scrollbar-width: none;
    margin: 0mm;
  }

  .no-print {
    display: none !important;
  }

  .card-body {
    padding: 0px !important;
  }

  #root {
    display: none !important;
  }

  .modal-content {
    box-shadow: none !important;
  }

  body,
  #kt_modal_print {
    height: max-content !important;
  }

  .modal-body {
    overflow: hidden;
  }

  .modal-dialog {
    max-width: unset;
  }
}

.text-xs {
  font-size: 0.75rem !important;
}

.white-to-black {
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);
}

.trx-print {
  hr {
    border-style: dashed;
  }
}

.select-dashboard {
  button:hover img {
    filter: brightness(0) invert(1);
  }
}
